<template>
  <div class="start-pay">
    <div class="bg-white rounded p-4 shadow">
      <div class="text-center py-4" v-if="isLoading">
        <b-spinner small></b-spinner>
      </div>
      <div v-else-if="errorPage">
        <PaymentReturn />
      </div>
      <div v-else-if="activeTax.status !== 6">
        <div class="text-dark-blue">
          <div class="card-2 text-center">
            <h4>
              {{ $t("welcome") }} {{ activeUser.name }}
              {{ activeUser.surname }},
            </h4>
            <div
              v-html="$t('taxYearListTitle', { year: activeTax.taxYear })"
            ></div>
            <p class="text-muted mt-3">
              {{
                $t("applyTaxDescription", {
                  date: new Date(activeTax.completedDate).toLocaleString(
                    $i18n.locale
                  ),
                })
              }}
            </p>
            <div class="mt-3">
              <button v-if="activeTax.isPayment" class="blue-button bg-success">
                <FontAwesomeIcon class="check-icon" :icon="faCheckCircle" />
                {{ $t("paySucceed") }}
              </button>
              <button
                v-else-if="activeTax.status === 6"
                class="blue-button"
                @click="
                  $router.push(`/pay/${activeTax.userId}/${activeTax.taxYear}`)
                "
              >
                {{ $t("payIt") }}
              </button>
              <button v-else disabled class="blue-button disabled">
                {{ $t("taxWaiting") }}
              </button>
            </div>
            <div
              class="mt-4 w-100 alert alert-warning"
              v-if="activeTax.status !== 6"
            >
              {{ $t("taxWaitingDescription") }}
            </div>
            <div class="mt-4 w-100 alert alert-success" v-else>
              {{ $t("taxDoneDescription") }}
            </div>
          </div>
        </div>
      </div>
      <Payment
        single-pay
        v-else-if="activeTax"
        :data="activeTax"
        :userId="activeTax.userId"
        :isVisible="true"
      />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFile,
  faCreditCard,
  faCheckCircle,
  faClipboard,
} from "@fortawesome/free-regular-svg-icons";
import { mapGetters } from "vuex";
import { getPaymentsOfUser } from "../../services/modules/User";
import Payment from "../register/Payment.vue";
import PaymentReturn from "./paymentReturn.vue";
export default {
  components: {
    PaymentReturn,
    FontAwesomeIcon,
    Payment,
  },
  data() {
    return {
      faFile,
      faCheckCircle,
      faCreditCard,
      faClipboard,
      isLoading: true,
      taxYearList: [],
      activeTax: null,
      errorPage: false,
    };
  },
  computed: {
    ...mapGetters(["activeUser"]),
  },
  methods: {
    async GET_PAYMENT_OF_USER() {
      this.isLoading = true;
      console.log(this.activeUser);
      const response = await getPaymentsOfUser(this.activeUser.id);
      if (response.message === "OK") {
        this.taxYearList = response.data;
        const params = this.$route.params;
        this.activeTax =
          response.data.find(
            (d) =>
              d.taxYear === Number(params.taxYear) &&
              d.userId === Number(params.userId) &&
              this.activeUser.id === Number(params.userId)
          ) || null;
        if (!this.activeTax) this.errorPage = true;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.GET_PAYMENT_OF_USER();
  },
};
</script>

<style lang="scss">
.start-pay {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/img/image-3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  & .bg-white {
    width: 1200px;
    max-width: 100%;
  }
}
</style>
