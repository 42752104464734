<template>
  <div class="payment-page d-flex justify-content-center align-items-center">
    <div>
      <div class="text-center mb-4">
        <img :src="require('../../assets/img/errorBuy.png')" alt="error" />
      </div>
      <div class="text-center">
        <h2
          class="card-title font-weight-bold"
          :style="{ color: isReturn === 'CANCEL' ? '#ff0000' : '#50b748' }"
        >
          {{ $t("notAuthForPayment") }}
        </h2>
        <p class="mb-3">
          {{ $t("transaction") }}
          {{
            isReturn === "CANCEL" ? $t("errorMessage") : $t("successMessage")
          }}
          {{ $t("transactionOneText") }}
          <span class="font-weight-bold">"mail@flinktax.de"</span>
          {{ $t("transactionTwoText") }}
        </p>
        <h6>{{ $t("infoPayment") }}</h6>
        <div class="mt-4">
         <router-link to="/start">
              <button class="blue-button">{{ $t("goToAccount") }}</button>
         </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isReturn: {
      default: "CANCEL",
    },
  },
};
</script>

<style lang="scss">
.payment-page {
  height: 100vh;
  p {
    font-size: 16px;
  }
}
</style>
