<template>
  <div class="payment">
    <h3 class="text-blue mb-3">{{ $t("payment.paymentOneText") }}</h3>
    <p class="text-dark-blue mb-3">
      {{
        singlePay
          ? $t("paymentSucceedDescription")
          : $t("payment.paymentTwoText")
      }}
    </p>
    <h3 class="text-dark-blue">{{ $t("payment.taxPrice") }}</h3>
    <h2
      class="text-blue"
      :class="form.discountCodeId && form.discount > 0 ? 'price-line' : ''"
    >
      {{ form.isPartnerPayment == true ? "169,00 €" : "119,00 €" }}
    </h2>
    <h1 class="text-blue" v-if="form.discountCodeId && form.discount > 0">
      <!-- {{ `${Math.round(form.discountPrice)},00 €` }} -->
      <!-- {{ Math.round(form.discountPrice) - form.discountPrice }} -->
      {{ form.discountPrice }} €
    </h1>
    <div class="d-flex align-items-center justify-content-center mb-4 mt-5">
      <div class="radio-button">
        <input
          type="radio"
          id="paypal"
          name="payment"
          :value="paymentOptions.PAYPAL"
          v-model="paymentOption"
        />
        <label for="paypal" class="mr-2 w-100">
          <div class="d-flex align-items-center justify-content-center">
            <img
              src="../../assets/img/icon/paypal.png"
              alt="stripe"
              height="40"
            />
          </div>
        </label>
      </div>
      <div class="radio-button">
        <input
          type="radio"
          id="stripe"
          name="payment"
          :value="paymentOptions.STRIPE"
          v-model="paymentOption"
        />
        <label for="stripe" class="mr-2 w-100">
          <div class="d-flex align-items-center justify-content-center">
            <img
              src="../../assets/img/icon/atm-card.png"
              alt="stripe"
              height="24"
              class="mr-2"
            />
            <p class="font-size-12 credit-card-font">
              {{ $t("payment.creditCard") }}
            </p>
          </div>
        </label>
      </div>
    </div>
    <div class="d-flex discount-button col-12 col-md-7 col-xl-5">
      <b-form-input
        type="text"
        id="code"
        :placeholder="$t('enterDiscountCode')"
        v-model="form.discountCode"
      />
      <div
        class="blue-button"
        @click="form.discountCode?.length == 0 ? '' : applyDiscountCode()"
        :style="{
          cursor: form.discountCode?.length == 0 ? 'auto' : 'pointer',
        }"
      >
        <span v-if="!discountLoading"> {{ $t("apply") }}</span>
        <b-spinner variant="light" v-else></b-spinner>
      </div>
    </div>
    <p v-if="isDiscountShow.show" :style="{ color: isDiscountShow.color }">
      {{ $t(isDiscountShow.message) }}
    </p>
    <b-form-group class="mt-5">
      <b-form-checkbox
        id="checkbox-1"
        name="checkbox-1"
        :value="true"
        v-model="terms"
      >
        <label>
          {{ $t("paymentOneText") }}
          <span
            class="check-link text-dark-blue"
            @click="cancelConditionModal = true"
            >{{ $t("cancelCondition") }}</span
          >
          {{ $t("paymentTwoText") }}
        </label>
        {{ $t("zuu") }}
      </b-form-checkbox>
      <div v-if="!terms">
        <small class="text-danger">{{ $t("pleaseAcceptTerms") }}</small>
      </div>
    </b-form-group>
    <h6 class="text-danger" v-if="fileNotFound">
      {{ $t("fileNotFound") }}
    </h6>
    <button
      class="blue-button mt-3"
      @click="
        (e) => (isVisible ? updatePaymentTaxReturn(e) : paymentTaxReturn(e))
      "
    >
      <span v-if="!loading">{{ $t("payment.taxPriceButton") }}</span>
      <b-spinner variant="light" v-else></b-spinner>
    </button>
    <div v-if="paymentLink !== ''">
      <iframe
        :src="paymentLink"
        width="100%"
        height="600px"
        frameborder="0"
      ></iframe>
    </div>
    <!-- <div>
      <p class="text-dark-blue font-size-14">
        {{ $t("payment.paymentInfoText") }}
      </p>
    </div> -->
    <b-modal
      centered
      v-model="cancelConditionModal"
      hide-footer
      id="cancel-condition-modal"
    >
      <CancelCondition />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { addDiscountCode } from "../../services/modules/Campaign";
import { paymentControl, paymentTax } from "../../services/modules/User";
import CancelCondition from "../information/CancelCondition.vue";
import paymentOptions from "../../enums/paymentOptions";
export default {
  name: "Payment",
  props: {
    userId: {
      default: null,
    },
    nextButton: {
      type: Function,
    },
    data: {
      type: Object,
      default: () => {},
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    singlePay: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CancelCondition,
  },
  data() {
    return {
      paymentOptions,
      paymentOption: 2,
      isSuccess: false,
      paymentLink: "",
      loading: false,
      discountLoading: false,
      isPaymentVisible: false,
      cancelConditionModal: false,
      form: {},
      isDiscountShow: {
        show: false,
        message: "",
        color: "",
      },
      isCheckStep: false,
      terms: false,
      fileNotFound: false,
    };
  },
  computed: {
    ...mapGetters(["activeUser", "getWebView", "getWebViewApp"]),
  },
  methods: {
    async applyDiscountCode() {
      this.discountLoading = true;
      const response = await addDiscountCode(
        this.form.discountCode,
        this.userId,
        this.form.isPartnerPayment
      );

      if (response.message === "OK") {
        this.form.discountCodeId = response.data.id;
        this.form.discount = response.data.discount;
        this.form.isCompanyCode = response.data.isCompanyCode;
        const dn = response.data.discountPrice
          .toFixed(2)
          .toString()
          .replace(".", ",");
        this.form.discountPrice = dn;
        this.isDiscountShow.show = true;
        this.isDiscountShow.color = "green";
        this.isDiscountShow.message = "campany.discountSuccess";
      } else {
        this.isDiscountShow.show = true;
        this.isDiscountShow.color = "red";
        this.isDiscountShow.message = "campany.discountNoSuccess";
      }

      this.discountLoading = false;

      setTimeout(() => {
        this.isDiscountShow.show = false;
      }, 3000);
    },
    CHECK_STEP() {
      return this.activeUser.id ? this.isCheckStep : this.isSuccess;
    },
    async paymentTaxControl() {
      const response = await paymentControl(this.userId);

      if (response.message === "OK") {
        this.isSuccess = response.data;

        if (this.isSuccess) {
          this.isPaymentVisible = false;
          this.$emit("nextButton");
        }
      }
    },
    paymentControlUser(answer) {
      this.isPaymentVisible = false;

      if (answer === "SUCCESS") {
        this.isCheckStep = true;
        this.$emit("nextButton");
      } else {
        this.isCheckStep = false;
      }
    },
    async paymentTaxReturn(e) {
      if (this.terms) {
        var windowReference = null;
        e.preventDefault();
        if (!this.getWebViewApp) {
          windowReference = window.open();
        }
        this.loading = true;
        const response = await paymentTax(
          this.userId,
          this.paymentOption,
          this.$i18n.locale,
          this.data.taxYear,
          "Register",
          this.form.discountCodeId,
          this.form.isPartnerPayment
        );
        if (response?.exceptionMessage === "filenotfound") {
          this.fileNotFound = true;
        } else if (response.message === "OK") {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              link: response.data,
            })
          );
          setTimeout(() => {
            if (windowReference !== null)
              windowReference.location = response.data;
          }, 500);

          setTimeout(() => {
            this.fileNotFound = false;
          }, 3000);
          // a.appendChild(linkText);
          // a.title = "my title text";
          // a.href = response.data;
          // document.body.appendChild(a);
        }
        this.loading = false;
      }
    },
    async updatePaymentTaxReturn(e) {
      if (this.terms) {
        var windowReference = null;
        e.preventDefault();
        if (!this.getWebViewApp) {
          windowReference = window.open();
        }
        this.loading = true;

        const response = await paymentTax(
          this.userId,
          this.paymentOption,
          this.$i18n.locale,
          this.data.taxYear,
          "Update",
          this.form.discountCodeId,
          this.form.isPartnerPayment
        );

        if (response?.exceptionMessage === "filenotfound") {
          this.fileNotFound = true;
        } else if (response.message === "OK") {
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              link: response.data,
            })
          );
          setTimeout(() => {
            if (windowReference !== null)
              windowReference.location = response.data;
          }, 500);
          setTimeout(() => {
            this.fileNotFound = false;
          }, 3000);
          // var a = document.createElement("a");
          // a.title = "my title text";
          // a.href = response.data;
          // a.target = "_blank";
          // a.click();
          // document.body.appendChild(a);
        }
        this.loading = false;
      }
    },
  },
  mounted() {
    this.form = this.data;
    this.isPaymentVisible = true;
    this.$emit("isPaymentVisible", this.isPaymentVisible);
    // this.$router.replace({ query: { payment: true } });

    window.addEventListener(
      "message",
      (event) => {
        if (event.data?.message === "requestCheck") {
          if (!this.activeUser.id) {
            this.paymentTaxControl();
          } else {
            this.paymentControlUser(event.data?.answer);
          }
        }
      },
      false
    );
  },
  beforeDestroy() {
    this.isPaymentVisible = false;
    this.$emit("isPaymentVisible", this.isPaymentVisible);
  },
};
</script>

<style lang="scss">
.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .price-line {
    text-decoration: line-through;
    color: rgba(213, 14, 34, 0.942);
  }

  .radio-button {
    border: 1px solid #161640;
    padding: 0px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    flex-direction: row-reverse;
    margin-bottom: 20px;

    &:not(:last-child) {
      margin-right: 20px;
    }
    input {
      height: 22px;
      width: 22px;
    }

    input[type="radio"]:checked ~ label {
      color: rgb(87, 198, 255);
    }

    input[type="radio"]:checked ~ .radio-button {
      border: 1px solid red;
    }

    input[type="radio"]:checked {
      position: relative;

      &::before {
        content: "\2713";
        position: absolute;
        background-color: rgb(87, 198, 255);
        color: white;
        height: 22px;
        width: 22px;
        border-radius: 50rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    label {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      height: 36px;
    }
  }

  .discount-button {
    position: relative;
    display: flex;
    align-items: center;

    input {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-right: none !important;

      &::placeholder {
        font-size: 13px;
      }
      &:focus {
        box-shadow: none;
        border: 1px solid #57c6ff;
      }
    }
    div {
      position: absolute;
      right: 0px;
      padding: 0.375rem 1.6rem;
      height: calc(1.5em + 0.75rem + 2px);
      cursor: pointer;
    }
  }
}

#cancel-condition-modal {
  .modal-header {
    position: fixed;
    z-index: 999;
    background-color: #57c6ff;
    border-bottom: none;
    border-radius: none;
  }

  .close {
    color: #fff;
    opacity: 1;
  }
  .modal-content {
    max-height: 600px;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  .credit-card-font {
    font-size: 9px !important;
  }
}
</style>
