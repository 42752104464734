<template>
  <div class="container cancel-condition">
    <div class="col-12">
      <h6 class="text-blue mb-3">Widerrufsbelehrung</h6>
      <h6 class="text-blue mb-2">Widerrufsrecht</h6>
      <p class="mb-5">
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
        diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage
        ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben,
        müssen Sie uns, der IHGP STEUER GmbH Steuerberatungsgesellschaft
        Oranienstraße 155 10969 Berlin Telefon: +49 30 6110186-0 Fax: +49 30
        6110186-28 E-Mail: kanzlei@ihgp-steuer.de mittels einer eindeutigen
        Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail)
        über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie
        können dafür das beigefügte Muster-Widerrufsformular verwenden, das
        jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht
        es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts
        vor Ablauf der Widerrufsfrist absenden.
      </p>
      <h6 class="mb-2 text-blue">Folgen des Widerrufs</h6>
      <p>
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
        wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
        Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
        andere Art der Lieferung als die vom Verkäufer angebotene, günstigste
        Standardlieferung gewählt haben), unverzüglich und spätestens binnen
        vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
        Ihren Widerrufs dieses Vertrages bei uns eingegangen ist. Für die
        Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
        ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
        wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
        wegen dieser Rückzahlung Entgelte berechnet. Haben Sie verlangt, dass
        die Dienstleistungen während der Widerrufsfrist beginnen sollen, so
        haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der
        bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des
        Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits
        erbrachten Leistungen im Vergleich zum Gesamtumfang der im Vertrag
        vorgesehen Leistungen entspricht.
      </p>
    </div>
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style lang="scss">
.cancel-condition {
  margin-top: 60px;
  text-align: justify;
  
  ul {
    list-style-type: circle;
  }

  ol {
    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &::marker {
        color: #57c6ff;
        font-weight: bold;
      }
    }
  }
}
</style>